import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import habboOrigins from './assets/habbo+origins.gif';
import secretSanta from './assets/secret+santa.gif';
import Home from './pages/Home';
import Players from './pages/Players';
import Rules from './pages/Rules';

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [habboName, setHabboName] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [step, setStep] = useState(1);

  const generateRandomCode = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    return Array.from({ length: 10 }, () => chars[Math.floor(Math.random() * chars.length)]).join('');
  };

  const openModal = () => {
    setIsModalOpen(true);
    setStep(1);
    setStatusMessage('');
    setVerificationCode('');
    setHabboName('');
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleNameSubmit = async () => {
    try {
      const response = await fetch(`https://origins.habbo.com/api/public/users?name=${habboName}`);
      const data = await response.json();

      if (data.error === 'not-found') {
        setStatusMessage("Sorry, Habbo not found. Either you don't exist or your profile is set to private.");
      } else {
        const code = generateRandomCode();
        setVerificationCode(code);
        setStatusMessage(`We need to verify that it's you. To do this, change your motto to: "${code}"`);
        setStep(2);
      }
    } catch (error) {
      setStatusMessage("An error occurred. Please try again.");
    }
  };

  const handleVerification = async () => {
    try {
      const response = await fetch(`https://origins.habbo.com/api/public/users?name=${habboName}`);
      const data = await response.json();

      if (data.motto === verificationCode) {
        setStatusMessage("Verification successful! You are registered.");
        await registerUser();
        setStep(3);
      } else {
        setStatusMessage("Motto does not match. Please check that you've changed it and try again.");
      }
    } catch (error) {
      setStatusMessage("An error occurred. Please try again.");
    }
  };

  const registerUser = async () => {
    try {
      const response = await fetch(`/api/Register?HabboName=${encodeURIComponent(habboName)}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const result = await response.json();

      if (result.success) {
        setStatusMessage("You're all signed up!");
      } else {
        setStatusMessage(`Uh Oh... Something went wrong.`);
      }
    } catch (error) {
      setStatusMessage(":)");
    }
  };

  return (
    <Router>
      <div className="h-screen bg-repeat flex flex-col items-center justify-center">
        {/* GIF Images */}
        <div className="flex flex-col items-center mb-4">
          <img src={habboOrigins} alt="Habbo Origins" className="mb-2" />
          <img src={secretSanta} alt="Secret Santa" /><br />
        </div>

        {/* Navigation Bar */}
        <div className="flex justify-center space-x-4 w-full max-w-[700px] mb-4">
          <Link to="/" className="bg-blue-200 text-white font-bold py-2 px-4 w-[20%] rounded-md text-center">HOME</Link>
          <Link to="/players" className="bg-blue-200 text-white font-bold py-2 px-4 w-[20%] rounded-md text-center">PLAYERS</Link>
          <Link to="/rules" className="bg-blue-200 text-white font-bold py-2 px-4 w-[20%] rounded-md text-center">RULES</Link>
        </div>

        {/* Content Box with Routes */}
        <div className="w-[90%] max-w-[700px] p-4 bg-white border border-gray-200 rounded-lg shadow-md">
          <Routes>
            <Route path="/" element={<Home openModal={openModal} />} />
            <Route path="/players" element={<Players />} />
            <Route path="/rules" element={<Rules />} />
          </Routes>
        </div>

        {/* Modal */}
        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-[90%] max-w-[500px] relative">
              <button className="absolute top-2 right-2 text-gray-600" onClick={closeModal}>X</button>

              {step === 1 && (
                <form className="space-y-4">
                  <h2 className="text-lg font-bold">Enter Your Habbo Name</h2>
                  <input
                    type="text"
                    value={habboName}
                    onChange={(e) => setHabboName(e.target.value)}
                    placeholder="Habbo Name"
                    className="w-full border border-gray-300 rounded-md p-2"
                  />
                  <button type="button" onClick={handleNameSubmit} className="w-full bg-blue-200 text-white font-bold py-2 rounded-md">
                    Submit
                  </button>
                </form>
              )}

              {step === 2 && (
                <div className="text-center space-y-4">
                  <p>Great, Habbo Found!</p>
                  <button onClick={handleVerification} className="w-full bg-blue-200 text-white font-bold py-2 rounded-md">
                    Verify
                  </button>
                </div>
              )}

              {step === 3 && (
                <div className="text-center">
                  <p>{statusMessage}</p>
                </div>
              )}

              {statusMessage && <p className="mt-4 text-sm text-center text-gray-600">{statusMessage}</p>}
            </div>
          </div>
        )}
      </div>
    </Router>
  );
}

export default App;
