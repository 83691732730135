import React, { useEffect, useState } from 'react';
import loader from '../assets/ajax-loader.gif';

function Players() {
    const [players, setPlayers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const playersPerPage = 15;

    useEffect(() => {
        // Fetch player data from the API
        const fetchPlayers = async () => {
            try {
                const response = await fetch('/api/AllUsers/');
                const data = await response.json();
                setPlayers(data);
            } catch (error) {
                console.error("Error fetching player data:", error);
            } finally {
                setLoading(false);
            }
        };
        
        fetchPlayers();
    }, []);

    // Calculate current page players
    const indexOfLastPlayer = currentPage * playersPerPage;
    const indexOfFirstPlayer = indexOfLastPlayer - playersPerPage;
    const currentPlayers = players.slice(indexOfFirstPlayer, indexOfLastPlayer);

    // Calculate total pages
    const totalPages = Math.ceil(players.length / playersPerPage);

    // Pagination controls
    const nextPage = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    const prevPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));

    if (loading) {
        return (
            <>
                <div className="bg-blue-200 h-[35px] rounded-lg flex items-center justify-center">
                    <h2 className="text-center font-bold text-lg text-white">Habbo Origins Secret Santa Players</h2>
                </div>
                <div className="flex items-center justify-center">
                    <img src={loader} alt="Loading..." />
                </div>
            </>
        );
    }

    return (
        <div>
            <div className="bg-blue-200 h-[35px] rounded-lg flex items-center justify-center">
                <h2 className="text-center font-bold text-lg text-white">Habbo Origins Secret Santa Players</h2>
            </div>
            <p className="text-left mt-4 text-sm text-[#686869]">
                Want to know who's signed up to take part? Want to see if your friend has joined? Check the list below!
            </p>

            {/* Player Avatars Grid */}
            <div className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-5 gap-4 mt-4">
                {currentPlayers.map((player) => (
                    <div key={player} className="flex flex-col items-center">
                        <img
                            src={`https://habboden.com/habbo-imaging/${player}?size=s&gesture=sml`}
                            alt={player}
                        />
                        <p className="text-sm text-[#686869] mt-2">{player}</p>
                    </div>
                ))}
            </div><br /><br />

            {/* Pagination Controls */}
            <div className="flex justify-center mt-4 space-x-4">
                <button
                    onClick={prevPage}
                    disabled={currentPage === 1}
                    className="px-4 py-2 bg-blue-200 text-white font-bold rounded-md disabled:opacity-50"
                >
                    Previous
                </button>
                <span className="text-sm text-[#686869]">
                    Page {currentPage} of {totalPages}
                </span>
                <button
                    onClick={nextPage}
                    disabled={currentPage === totalPages}
                    className="px-4 py-2 bg-blue-200 text-white font-bold rounded-md disabled:opacity-50"
                >
                    Next
                </button>
            </div>
        </div>
    );
}

export default Players;
