import React from 'react';

function Home({ openModal }) {
  return (
    <>
      <div className="bg-blue-200 h-[35px] rounded-lg flex items-center justify-center">
        <h2 className="text-center font-bold text-lg text-white">Welcome to Secret Santa 2024!</h2>
      </div>
      <p className="text-left mt-4 text-sm text-[#686869]">
        Welcome to the Habbo Origins Secret Santa 2024! This year, our Secret Santa is exclusively for the English Hotel, but we’re hoping to expand it in the future.
      </p>
      <p className="text-left mt-4 text-sm text-[#686869]">
        Haven't registered yet? Click the button below to join the fun! Registration is open from 11/11/2024 until 01/12/2024 at 23:59. You can view current participants by clicking the <strong>Players</strong> link at the top of the page.
      </p>
      <p className="text-left mt-4 text-sm text-[#686869]">
        Once registration closes, you'll receive a message in the hotel (surprise!) with your Secret Santa partner. Be sure to review the game rules on the <strong>Rules</strong> page.
      </p>
      <p className="text-left mt-4 text-sm text-[#686869]">
        And there’s more! Throughout December, registered participants have a chance to make it onto Santa’s Nice List! The list is updated weekly, and those on it will receive that week's Rare item and Funky Friday gifts!
      </p>
      <div className="flex justify-center mt-4">
        <button onClick={openModal} className="bg-blue-200 text-white font-bold py-2 px-6 w-[70%] rounded-md">
          Register!
        </button>
      </div>
    </>
  );
}

export default Home;
