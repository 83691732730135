import React from 'react';

function Rules() {
    return (
        <div className="text-left">
            <div className="bg-blue-200 h-[35px] rounded-lg flex items-center justify-center">
                <h2 className="text-center font-bold text-lg text-white">Habbo Origins Secret Santa Rules</h2>
            </div>
            <p className="text-left mt-4 text-sm text-[#686869]">
                We do have <i>some</i> rules for this event. Please make sure you read below, before agreeing to participate.
            </p>
            <ul className="list-none text-left mt-4 space-y-2 text-sm text-[#686869]">
                <li>
                    <span role="img" aria-label="snowflake">❄️</span> <strong>Budget</strong>: The maximum budget for gifts is <strong>20 credits</strong>. Try to keep within this limit to make sure everyone can participate fairly.
                </li>
                <li>
                    <span role="img" aria-label="snowflake">❄️</span> <strong>Activity</strong>: Before you are matched with someone, we will check your hotel activity. If you haven't been active since registering, you will be removed.
                </li>
                <li>
                    <span role="img" aria-label="snowflake">❄️</span> <strong>Anonymity</strong>: Secret Santa is anonymous! Don’t reveal who you’re buying for until the gift exchange.
                </li>
                <li>
                    <span role="img" aria-label="snowflake">❄️</span> <strong>Gift Ideas</strong>: Choose something fun and thoughtful within the budget.
                </li>
                <li>
                    <span role="img" aria-label="snowflake">❄️</span> <strong>Deadline</strong>: Make sure to have your gift ready by the agreed date. Late gifts spoil the fun!
                </li>
                <li>
                    <span role="img" aria-label="snowflake">❄️</span> <strong>Exchange Day</strong>: We’ll exchange gifts on the designated weekend. This will be the 21st & 22nd December.
                </li>
                <li>
                    <span role="img" aria-label="snowflake">❄️</span> <strong>Have Fun</strong>: The most important rule! Remember, this is about having fun and spreading holiday cheer.
                </li>
                
            </ul>
        </div>
    );
}

export default Rules;
